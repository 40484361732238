import React, { useState, useEffect, useRef } from "react";
import Downarrow from "assets/downarrow.svg";
import VideoModal from "./modal";
import Videoplay from "assets/videoplay.svg";

const LargeVideo = ({
  pageData,
  toggleModal,
  showModal,
  setShowModal,
  selectedVideo,
  formatDate,
  setSelectedVideo,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [visibleVideos, setVisibleVideos] = useState([]);
  const [showvideos, setShowvideos] = useState();
  const firstVideoRef = useRef(null);

  const ShowVideo = () => {
    setShowAll(!showAll);

    if (showAll && firstVideoRef.current) {
      firstVideoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  setShowvideos(pageData.widgets[0].data.content_videos);

  useEffect(() => {
    const updateVisibleVideos = () => {
      if (showAll) {
        setVisibleVideos(pageData.widgets[0].data.content_videos);
      } else if (window.innerWidth >= 1024) {
        setVisibleVideos(pageData.widgets[0].data.content_videos.slice(0, 8));
      } else if (window.innerWidth >= 767) {
        setVisibleVideos(pageData.widgets[0].data.content_videos.slice(0, 4));
      } else {
        setVisibleVideos(pageData.widgets[0].data.content_videos.slice(0, 2));
      }
    };

    updateVisibleVideos();

    const handleResize = () => {
      updateVisibleVideos();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showAll, pageData.widgets[0].data.content_videos]);

  return (
    <div className="mt-12 pt-5 px-4 sm:px-6 md:py-12 max-w-7xl mx-auto text-[#00173A] py-16">
      <div>
        <h1 className="leading-9 text-[#00173A] font-primary font-semibold text-3xl">
          Videos
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-4">
        {visibleVideos.map((video, index) => (
          <div
            key={index}
            className="relative py-8"
            ref={index === 0 ? firstVideoRef : null}
          >
            <div className="relative">
              <img
                src={video.thumbnail}
                alt={`Video ${index + 1}`}
                className="object-cover w-full h-full md:h-[153px] aspect-ratio cursor-pointer"
                // onClick={() => toggleModal(video.link)}
              />

              <div className="absolute top-0 left-0 w-full h-full  flex items-center justify-center ">
                <div className="w-full h-full bg-[#000000] opacity-30"></div>
                <img
                  src={Videoplay}
                  onClick={() => toggleModal(video.link)}
                  className="cursor-pointer absolute"
                />
              </div>
            </div>

            <p className="text-[#00173A] text-sm font-opensans font-semibold mt-2 h-12 ">
              {video.title}
            </p>
            <p className="text-[#00173A] text-base font-opensans font-semibold mt-2">
              {formatDate(video.date)}
            </p>
          </div>
        ))}
      </div>

      {typeof window !== "undefined" &&
        ((window.innerWidth >= 1024 && showvideos?.length > 8) ||
        (window.innerWidth >= 767 &&
          window.innerWidth < 1024 &&
          showvideos?.length >= 4) ||
        (window.innerWidth < 767 && showvideos?.length > 2) ? (
          <div className="flex items-center mt-8">
            <hr className="flex-1 border-t border-[#649DF2] mr-4 md:block hidden" />
            <button
              onClick={ShowVideo}
              className="bg-white text-[#649DF2] border border-[#649DF2] py-2 px-6 flex gap-2 items-center justify-center rounded-md cursor-pointer w-full md:w-max"
            >
              <p>{showAll ? "Show less" : "Show more"}</p>
              <img
                src={Downarrow}
                alt="Arrow"
                className={`${
                  showAll
                    ? "rotate-180 transition duration-360 ease-in-out"
                    : "rotate-0 transition duration-360 ease-in-out"
                }`}
              />
            </button>
            <hr className="flex-1 border-t border-[#649DF2] ml-4 md:block hidden" />
          </div>
        ) : null)}
      {showModal && (
        <VideoModal
          videoUrl={selectedVideo}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default LargeVideo;
