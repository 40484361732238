import React from "react";
import ReactPlayer from "react-player/youtube";

const VideoModal = ({ videoUrl, onClose }) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-30 z-50  "
      onClick={onClose}
    >
      <div className="bg-white rounded-lg  max-w-xl ">
        <div className="relative aspect-w-16 aspect-h-9 ">
          <ReactPlayer
            url={videoUrl}
            playing={false}
            controls={true}
            muted={false}
            className="sm:block hidden"
          />
          <ReactPlayer
            url={videoUrl}
            playing={false}
            controls={true}
            muted={false}
            width={340}
            className="sm:hidden block "
          />
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
