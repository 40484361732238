import React, { useEffect, useRef, useState } from "react";
import Downarrow from "assets/downarrow.svg";
import VideoModal from "./modal";
import Videoplay from "assets/videoplay.svg";

const ShortVideo = ({
  pageData,
  toggleModal,
  showModal,
  setShowModal,
  selectedVideo,
  setSelectedVideo,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [visibleVideos, setVisibleVideos] = useState([]);
  const [showShortvideos, setShortShowvideos] = useState();
  const firstVideoRef = useRef(null);

  const ShowVideo = () => {
    setShowAll(!showAll);

    if (showAll && firstVideoRef.current) {
      firstVideoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  setShortShowvideos(pageData.widgets[0].data.shorts_videos);

  useEffect(() => {
    const updateVisibleVideos = () => {
      if (showAll) {
        setVisibleVideos(pageData.widgets[0].data.shorts_videos);
      } else if (window.innerWidth >= 1024) {
        setVisibleVideos(pageData.widgets[0].data.shorts_videos.slice(0, 4));
      } else if (window.innerWidth >= 767) {
        setVisibleVideos(pageData.widgets[0].data.shorts_videos.slice(0, 2));
      } else {
        setVisibleVideos(pageData.widgets[0].data.shorts_videos.slice(0, 1));
      }
    };
    updateVisibleVideos();

    const handleResize = () => {
      updateVisibleVideos();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showAll]);

  return (
    <div className="pt-5 px-4 sm:px-6 md:py-12 max-w-7xl mx-auto text-[#00173A] py-16">
      <div>
        <h1 className="leading-9 text-[#00173A] font-primary font-semibold text-3xl">
          Shorts
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-4">
        {visibleVideos.map(
          (video, index) =>
            video.thumbnail !== null && (
              <div key={index} className="relative py-8">
                {/* <a href={video.link} target="_blank" rel="noopener noreferrer"> */}
                <img
                  src={video.thumbnail}
                  alt={`Video ${index + 1}`}
                  ref={index === 0 ? firstVideoRef : null}
                  className="aspect-[9/16] cursor-pointer"
                  // onClick={() => toggleModal(video.link)}
                />
                {/* </a> */}
                <div className="absolute top-0 left-0 w-full h-full  flex items-center justify-center ">
                  {/* <div className="w-full h-full bg-[#000000] opacity-30"></div> */}
                  <img
                    src={Videoplay}
                    onClick={() => toggleModal(video.link)}
                    className="cursor-pointer absolute"
                  />
                </div>
                <p className="text-[#00173A] text-sm font-opensans font-semibold mt-2 h-12">
                  {video.title}
                </p>
              </div>
            )
        )}
      </div>

      {typeof window !== "undefined" &&
        ((window.innerWidth >= 1024 && showShortvideos?.length > 4) ||
        (window.innerWidth >= 767 &&
          window.innerWidth < 1024 &&
          showShortvideos?.length > 2) ||
        (window.innerWidth < 767 && showShortvideos?.length > 1) ? (
          <div className="flex items-center mt-8">
            <hr className="flex-1 border-t border-[#649DF2] mr-4" />
            <button
              onClick={ShowVideo}
              className="bg-white text-[#649DF2] border border-[#649DF2] py-2 px-6 flex gap-2 items-center justify-center w-full md:w-max rounded-md cursor-pointer"
            >
              <p>{showAll ? "Show less" : "Show more"}</p>
              <img
                src={Downarrow}
                alt="Arrow"
                className={`  ${
                  showAll
                    ? "rotate-180 transition duration-360 ease-in-out  "
                    : "rotate-0 transition duration-360 ease-in-out "
                } `}
              />
            </button>
            <hr className="flex-1 border-t border-[#649DF2] ml-4" />
          </div>
        ) : null)}
      {showModal && (
        <VideoModal
          videoUrl={selectedVideo}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default ShortVideo;
