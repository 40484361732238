import React, { useState } from "react";

import LargeVideo from "./largeVideos";
import ShortVideo from "./ShortVideo";
import Layout from "components/layout";
import SEO from "components/seo";
import VideoModal from "./modal";
import Videoplay from "assets/videoplay.svg";

const Videos = (props) => {
  const pageData = props.pageContext.node.data;
  const { meta_title, meta_description, meta_keywords } = pageData.meta;
  const featuredVideo = pageData.widgets[0].data.featured_video;
  const sidebarVideos = pageData.widgets[0].data.sidebar_videos;
  const [showModal, setShowModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  const toggleModal = (videoUrl) => {
    setShowModal(!showModal);
    setSelectedVideo(videoUrl);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options).toUpperCase();
  };
  return (
    <Layout>
      <SEO
        title={meta_title}
        description={meta_description}
        keywords={meta_keywords}
        slug="/resources/videos"
      />
      <div className=" my-16 pt-5 px-4 sm:px-6 md:py-12 max-w-7xl mx-auto text-[#00173A] py-16">
        <div>
          <h1 className="text-[#00173A] font-primary font-bold text-5xl leading-[62px]">
            Tomedes Video Hub:
            <br /> Explore Translation Tips and More
          </h1>
        </div>
        <div className=" lg:flex mt-16 justify-between gap-[56px]">
          <div className="lg:w-[767px] ">
            <div
              className="relative rounded-xl bg-cover h-[400px] lg:h-full bg-center text-white  "
              style={{
                backgroundImage: `url(${featuredVideo.thumbnail})`,
              }}
            >
              <div className="bg-gradient-to-t from-[#00173A] to-transparent h-full rounded-xl"></div>

              <div className="absolute bottom-10 p-8">
                <p className="text-4xl font-semibold font-primary ">
                  {featuredVideo.title}
                </p>
                <p className="mt-1 text-[#FFFFFF]">
                  {formatDate(featuredVideo.date)}
                </p>
              </div>
              <div className="absolute top-0 left-0 w-full h-full  flex items-center justify-center ">
                <img
                  src={Videoplay}
                  onClick={() => toggleModal(featuredVideo.link)}
                  className="cursor-pointer absolute z-50"
                />
              </div>
            </div>
          </div>
          <div className="lg:w-[437px] flex flex-col gap-6 mt-12 lg:mt-0">
            {sidebarVideos.slice(0, 3).map(
              (sidebarVideo, index) =>
                sidebarVideo.thumbnail !== null && (
                  <div
                    className=" relative aspect-videos rounded-xl bg-green-500 bg-cover bg-center h-48 lg:h-40  text-white text-base font-opensans leading-7 font-semibold "
                    style={{
                      backgroundImage: `url(${sidebarVideo.thumbnail})`,
                      borderRadius: "8px",
                    }}
                    // onClick={() => toggleModal(sidebarVideo.link)}
                  >
                    <div className="bg-gradient-to-t from-[#00173A] to-transparent h-full rounded-xl"></div>

                    <p className="absolute bottom-4 px-8 pt-8  text-white ">
                      {sidebarVideo.title}
                    </p>
                    <div className="absolute top-0 left-0 w-full h-full  flex items-center justify-center ">
                      <img
                        src={Videoplay}
                        onClick={() => toggleModal(sidebarVideo.link)}
                        className="cursor-pointer absolute z-50"
                      />
                    </div>
                  </div>
                )
            )}
            {showModal && (
              <VideoModal
                videoUrl={selectedVideo}
                onClose={() => setShowModal(false)}
              />
            )}
          </div>
        </div>
        <LargeVideo
          pageData={pageData}
          toggleModal={toggleModal}
          showModal={showModal}
          setShowModal={setShowModal}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          formatDate={formatDate}
        />
        <ShortVideo
          pageData={pageData}
          toggleModal={toggleModal}
          showModal={showModal}
          setShowModal={setShowModal}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
        />
      </div>
    </Layout>
  );
};

export default Videos;
